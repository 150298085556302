import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-payment-status-model',
  templateUrl: './lead-type-change.component.html'
})
export class LeadTypeModelComponent implements OnInit {

  constructor(public db:DatabaseService,@Inject(MAT_DIALOG_DATA) public data: any,private _location: Location) { }
  ngOnInit() {
    this.formsInit(this.data);
    this.getData()
  }

  form: FormGroup;
  formsInit(data:any) {
    console.log(this.data);
    
    this.form = this.db.formBuilder.group({
      cust_type: [data.status ? data.status : null ,[Validators.required]],
      customer_id: [data.encrypt_id ? data.encrypt_id : null ,[Validators.required]],
    })
    console.log(this.form.value);

  }
  backClicked() {
    this._location.back();
  }
  type_list:any=[
      {id:3,name:'Carpenter'},
      {id:4,name:'Architect'}
  ];
  getData(){
    // if(this.form.invalid){
    //   this.form.markAllAsTouched()
    //   return;
    // }
    // let reqData = this.form.value
    // // if(reqData.status == 'Rejected') {this.db.presentAlert('Alert','Please proveide Remark!');return;}
    // this.db.presentLoader();
    // this.db.postReq(reqData,'dis_network/status_list').subscribe(resp=>{
    //   this.db.dismissLoader();
    //   if(resp['status'] == 'success'){
    //     this.status_list=resp['result'].data;
    //   }else{
    //     if(resp['message']=='Invalid token'){
    //       this.db.sessionExpire();
    //       return;
    //     }
    //     this.db.presentAlert(resp['status'],resp['message'])
    //   }
    // },err=>{
    //   this.db.errHandler(err);
    //   this.db.errDismissLoader();
    // })
    
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData = this.form.value
    reqData.cust_type_id = this.form.value.cust_type.id
    reqData.cust_type = this.form.value.cust_type.name
    if(reqData.status == 'Rejected') {this.db.presentAlert('Alert','Please proveide Remark!');return;}
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/update_type').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
}
