import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html'
})
export class UserHeaderComponent implements OnInit {
  status:boolean = false
  constructor(private render: Renderer2, private _location: Location, public router: Router) { }

  ngOnInit() {
  }


    
  backClicked() {
    this._location.back();
    // location.reload();
    this.status = !this.status;
    if(this.status) {
        //this.render.removeClass(document.body, 'toggle-active-tlp');
    }
    else {
        //this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }

}
