import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import * as moment from 'moment';
// import { type } from 'os';

@Component({
  selector: 'app-dr-add',
  templateUrl: './lead-add.component.html',
  // styleUrls: ['./dr-add.component.scss']
})
export class LeadAddComponent implements OnInit {
  type:any = this.activatedroute.snapshot.params.type
  typeName:any = this.type == 1 ? 'Distributor' : 'Customer'
  
  constructor(public events:CustomEventsService,public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    
    this.getSources();
    this.getStages();
    this.getDrType();
    this.getBank();
    this.getBranches();
    this.db.getStates();
    if(this.activatedroute.snapshot.params.id){
      this.getDetail(this.activatedroute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }  }
    detail = {};
    getDetail(id){
      this.db.presentLoader();
      
      this.db.postReq({'customer_id':id},'dis_network/detail').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.detail = resp['data']
          console.log('--->',this.detail)
          this.formsInit(resp['data']);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
      
      // this.db.postReq({'hsn_id':id},'master/hsn_detail').subscribe(resp=>{
      //   this.db.dismissLoader();
      //   if(resp['status'] == 'success'){
      //     this.formsInit(resp['data']['detail']);
      
      //   }else{
      //     if(resp['message']=='Invalid token'){
      //       this.db.sessionExpire();
      //       return;
      //     }
      //     this.db.presentAlert(resp['status'],resp['message'])
      //   }
      // },err=>{
      //   this.db.errHandler(err);
      // })
      
    }
    getDrType(){
      this.db.postReq({},'dis_network/type_list').subscribe(resp=>{
        this.db.drType = resp['result'].data
        this.db.drType.map(r=>r.id = r.id.toString())      
      })
    }
    start:any=0;
    limit:any=50;
    filter:any={};
    bankdata:any;
    getBank(){
      
      let reqData = {
        limit : 100,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'master/bank').subscribe(resp=>{
   
        if(resp['status'] == 'success'){
          this.bankdata = resp['result'].data;
        console.log('sffs',this.bankdata)
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
      this.db.errHandler(err);
        
      })
    }
    form: FormGroup;
    banks:any=[];
    filteredbanks:any=[]
    formsInit(data) {
      
      // this.db.http.get("assets/banks.json").subscribe(data =>{
      //   this.banks = data;
      //   this.filteredbanks = data
      // })
      
      
      this.form = this.db.formBuilder.group({
        company_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.company_name : null   , [Validators.required]],
        first_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.first_name : null   , [Validators.required]],
        last_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.last_name : null   , [Validators.required]],
        company_type : [this.activatedroute.snapshot.params.id ? data.basicDetail.company_type : null   , []],
        stage : [this.activatedroute.snapshot.params.id ? data.basicDetail.stage : null   , []],
        lead_source : [this.activatedroute.snapshot.params.id ? data.basicDetail.lead_source : null   , []],
        mobile : [this.activatedroute.snapshot.params.id ? data.basicDetail.mobile : null   , [Validators.required,Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/)]],
        date_of_birth : [this.activatedroute.snapshot.params.id ? moment(data.basicDetail.date_of_birth).format('YYYY-MM-DD') : null   , ],//[Validators.required]
        street : [this.activatedroute.snapshot.params.id ? data.basicDetail.street : null   , [Validators.required]],
        state_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.state_name : null   , [Validators.required]],
        district_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.district_name : null   , [Validators.required]],
        city : [this.activatedroute.snapshot.params.id ? data.basicDetail.city : null   , [Validators.required]],
        pincode : [this.activatedroute.snapshot.params.id ? data.basicDetail.pincode : null   , [Validators.required]],
        business_street : [this.activatedroute.snapshot.params.id ? data.basicDetail.business_street : null   , []],
        business_state_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.business_state_name : null   , []],
        business_district_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.business_district_name : null   , []],
        business_city : [this.activatedroute.snapshot.params.id ? data.basicDetail.business_city : null   , []],
        business_pincode : [this.activatedroute.snapshot.params.id ? data.basicDetail.business_pincode : null   , []],
        cust_type_id : [this.activatedroute.snapshot.params.id ? data.basicDetail.cust_type_id : null   , [Validators.required]],
        cust_id : [this.activatedroute.snapshot.params.id ? this.activatedroute.snapshot.params.id : null   , []],
        date_of_anniversary  : [this.activatedroute.snapshot.params.id ? moment(data.basicDetail.date_of_anniversary).format('YYYY-MM-DD') : null ],
        email : [this.activatedroute.snapshot.params.id ? data.basicDetail.email : null , [Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]],
        doc_type  : [ 'Adhar Card',],      //Validators.required
        doc_no : [ null,],       //Validators.required
        doc_image : [ null,],//Validators.required
        doc_image_1 : [ null,],//Validators.required
        pan_image: [this.activatedroute.snapshot.params.id && data.documentDetail.pan_image ? data.documentDetail.pan_image.replace(this.db.s3path,'') : null ,[Validators.required]],//Validators.required
        gst_image : [this.activatedroute.snapshot.params.id && data.basicDetail.gst_image ? data.basicDetail.gst_image.replace(this.db.s3path,'') : null ,[]],
        pan_no: [null,],//Validators.required
        profile_image : [ this.activatedroute.snapshot.params.id ?  data.basicDetail.profile_image : null,],//Validators.required
        image : [ null],
        image_1 : [ null],
        image_3 : [ this.activatedroute.snapshot.params.id ? data.basicDetail.profile_image : null],
        image_4 : [this.activatedroute.snapshot.params.id && data.documentDetail.pan_image ? data.documentDetail.pan_image.replace(this.db.s3path,'') : null ,[]],
        image_5 : [this.activatedroute.snapshot.params.id && data.basicDetail.gst_image ? data.basicDetail.gst_image.replace(this.db.s3path,'') : null ,[]],
        dealer:[this.activatedroute.snapshot.params.id && data.dealerDetail.length ? data.dealerDetail[0].dealer_id : null ],
        branch:[this.activatedroute.snapshot.params.id ? data.basicDetail.branch : null ],
        // dealer_mobile:[ this.activatedroute.snapshot.params.id ? (data.dealerDetail.length ? data.dealerDetail[0].mobile : '') : null, [Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/)]],
        // dealer_cp_name:[ this.activatedroute.snapshot.params.id ? (data.dealerDetail.length ? data.dealerDetail[0].cp_name : '') : null, []],
        // dealer_address:[ this.activatedroute.snapshot.params.id ? (data.dealerDetail.length ? data.dealerDetail[0].address : '') : null, []],
        // dealer_name:[ this.activatedroute.snapshot.params.id ? (data.dealerDetail.length ? data.dealerDetail[0].name : '') : null, []],
        customer_encrypt_id:[ this.activatedroute.snapshot.params.id ? this.activatedroute.snapshot.params.id : null, []],
        cp_email: [ []],
        cp_designation: [ null, []],
        cp_mobile: [null, []],
        cp_name: [ null , []],
        contactDetail: [this.activatedroute.snapshot.params.id ? data.contactDetail : null, [] , []],
        bank_name: [this.activatedroute.snapshot.params.id ? data.basicDetail.bank_name : null ,[]],
        account_number: [this.activatedroute.snapshot.params.id ? data.basicDetail.account_number : null ,[]],
        account_holder_name: [this.activatedroute.snapshot.params.id ? data.basicDetail.account_holder_name : null ,[]],
        ifsc_code: [this.activatedroute.snapshot.params.id ? data.basicDetail.ifsc_code : null ,[]],
        cheque_image: [this.activatedroute.snapshot.params.id && data.basicDetail.cheque_image ? data.basicDetail.cheque_image.replace(this.db.s3path,'') : null ,[]],
        c_image: [this.activatedroute.snapshot.params.id && data.basicDetail.cheque_image ? data.basicDetail.cheque_image.replace(this.db.s3path,'') : null ,[]],
        cheque_received: [this.activatedroute.snapshot.params.id ? data.basicDetail.cheque_received : null ,[]],
        gst_no: [this.activatedroute.snapshot.params.id ? data.basicDetail.gst_no : null ,[]],
        assignedUser: [[] , []],
        partner_name:[this.activatedroute.snapshot.params.id ? data.basicDetail.partner_name : null ,[]],
        partner_name_2:[this.activatedroute.snapshot.params.id ? data.basicDetail.partner_name_2 : null ,[]],
        partner_front : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_front ? data.basicDetail.partner_front.replace(this.db.s3path,'') : null ,[]],
        image_7 : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_front ? data.basicDetail.partner_front.replace(this.db.s3path,'') : null ,[]],
        partner_back : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_back ? data.basicDetail.partner_back.replace(this.db.s3path,'') : null ,[]],
        image_8 : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_back ? data.basicDetail.partner_back.replace(this.db.s3path,'') : null ,[]],
        partner_pan : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_pan ? data.basicDetail.partner_pan.replace(this.db.s3path,'') : null ,[]],
        image_9 : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_pan ? data.basicDetail.partner_pan.replace(this.db.s3path,'') : null ,[]],
        proprietorship_doc : [this.activatedroute.snapshot.params.id && data.basicDetail.proprietorship_doc ? data.basicDetail.proprietorship_doc.replace(this.db.s3path,'') : null ,[]],
        image_10 : [this.activatedroute.snapshot.params.id && data.basicDetail.proprietorship_doc ? data.basicDetail.proprietorship_doc.replace(this.db.s3path,'') : null ,[]],
        partner_front_2 : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_front_2 ? data.basicDetail.partner_front_2.replace(this.db.s3path,'') : null ,[]],
        partner_back_2 : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_back_2 ? data.basicDetail.partner_back_2.replace(this.db.s3path,'') : null ,[]],
        partner_pan_2 : [this.activatedroute.snapshot.params.id && data.basicDetail.partner_pan_2 ? data.basicDetail.partner_pan_2.replace(this.db.s3path,'') : null ,[]],
        police_station : [this.activatedroute.snapshot.params.id ? data.basicDetail.police_station : null, this.activatedroute.snapshot.params.type == 2 ? [Validators.required]: []],
        business_police_station : [this.activatedroute.snapshot.params.id ? data.basicDetail.business_police_station : null, this.activatedroute.snapshot.params.type == 2 ? [Validators.required]: []],
      })
      this.getUsers(data);
      this.db.getStates();
      this.db.getDistricts(this.form.value.state_name);
      this.db.getCity(this.form.value.state_name,this.form.value.district_name);
      this.getDistricts(this.form.value.business_state_name);
      this.getCity(this.form.value.business_state_name,this.form.value.business_district_name);
      if(!this.activatedroute.snapshot.params.id){ this.form.controls['cust_type_id'].setValue(this.type)} 
      if(this.activatedroute.snapshot.params.id){
        this.getDealers();
      }
      
      if (data.documentDetail) {
        data.documentDetail.map(r=>{
          // if(r.doc_type.toLowerCase().includes('company')){
          //   this.form.controls['company_doc'].setValue(r.doc_filename.replace(this.db.s3path,''));
          // }else 
          if(r.doc_type.toLowerCase().includes('pan')){
            this.form.controls['pan_no'].setValue(r.doc_type_value);
            this.form.controls['pan_image'].setValue(r.doc_filename.replace(this.db.s3path,''));
            this.form.controls['image_4'].setValue(r.doc_filename.replace(this.db.s3path,''));
          }else{
            this.form.controls['doc_no'].setValue(r.doc_type_value);
            this.form.controls['doc_type'].setValue(r.doc_type);
            this.form.controls['doc_image'].setValue(r.doc_filename.replace(this.db.s3path,''));
            this.form.controls['image'].setValue(r.doc_filename.replace(this.db.s3path,''));
            this.form.controls['doc_image_1'].setValue(r.doc_filename_1.replace(this.db.s3path,''));
            this.form.controls['image_1'].setValue(r.doc_filename_1.replace(this.db.s3path,''));
          }
        })
      }
      
    }
    addContactDetails(){
      console.log(this.form.value,this.form.value.cp_name ,this.form.value.cp_designation ,this.form.value.cp_email ,this.form.value.cp_mobile)
      if(!this.form.value.cp_name || !this.form.value.cp_designation || !this.form.value.cp_email || !this.form.value.cp_mobile){
        this.db.presentAlert('Error','Please fill all the required details to continue!');
        return;
      }
      var emailpattern = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
      console.log(emailpattern.test(this.form.value.cp_email));
      
      if(!emailpattern.test(this.form.value.cp_email)){
        this.db.presentAlert('Error','Please enter a valid email!');
        return;
      }
      var mobilepattern = /^((\\+91-?)|0)?[0-9]{10}$/
      if(!mobilepattern.test(this.form.value.cp_mobile)){
        this.db.presentAlert('Error','Please enter a valid mobile!');
        return;
      }
      this.form.controls['contactDetail'].setValue([...this.form.value.contactDetail,{name:this.form.value.cp_name ,email:this.form.value.cp_email,designation:this.form.value.cp_designation,mobile:this.form.value.cp_mobile}])
      this.form.controls['cp_name'].setValue(null);
      this.form.controls['cp_designation'].setValue(null);
      this.form.controls['cp_mobile'].setValue(null);
      this.form.controls['cp_email'].setValue(null);
    }
    deleteContact(index){
      swal({
        title: "Are you sure?",
        text: "You want to delete this!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {  
          this.form.controls['contactDetail'].setValue(this.form.value.contactDetail.filter((r,i)=> i != index ))
        } else {
          swal("Your data is safe!");
        }
      });
      
    }
    usersData:any=[]
    filteredUsersData:any=[]
    getUsers(data:any={}){
      let reqData = {
        limit : 30000,
        start : 0,
        filter : {}
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/sales_user').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.usersData = resp['result'].data;
          this.filteredUsersData = this.usersData
          
          // console.log(data.salesUserDetail.length);
          if(data.salesUserDetail){
            data.salesUserDetail.map(r=>{
              let i = this.usersData.findIndex(item => item.id == r.user_id)
              if(i!= -1){
                this.form.controls['assignedUser'].setValue([...this.form.value.assignedUser,this.usersData[i]]);
              }
            })
          }
          
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    
    branches:any=[];
    getBranches(){
      let reqData = {
        limit : 100000,
        start : 0,
        filter :{}
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'master/branch').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.branches = resp['result'].data;
          this.db.filteredResults.branches = resp['result'].data;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
        
      })
    }
    sources:any=[];
    getSources(){
      let reqData = {
        limit : 100000,
        start : 0,
        filter :{}
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/source_list').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.sources = resp['result'].data;
          this.db.filteredResults.sources = resp['result'].data;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
        
      })
    }
    stages:any=[];
    getStages(){
      let reqData = {
        limit : 100000,
        start : 0,
        filter :{}
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/stage_list').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.stages = resp['result'].data;
          this.db.filteredResults.stages = resp['result'].data;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
        
      })
    }
    dealers:any=[];
    getDealers(){
      let reqData = {
        limit : 100000,
        start : 0,
        filter :{
          "branch":this.form.value.branch,
          "cust_type_id": "2",
          "is_lead": "0",
          "status": ""
        }
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/list').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.dealers = resp['result'].data;
          this.db.filteredResults.dealers = resp['result'].data;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
        
      })
    }
    changeListener($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = ()=> {
        image = reader.result;
      // }
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['doc_image'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }
      
      reader.readAsDataURL(file);
      
    }
    
    changeListener_PAN($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = ()=> {
        image = reader.result;
      // }
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['pan_image'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }
      
      reader.readAsDataURL(file);
      
    }
    
    async changeListener_cheque($event): Promise<void> {
      
      console.log($event);
      var file = $event.target.files[0];
      console.log('file -->',file);
      var reader = await new FileReader();
      console.log('reader -->',reader);
      var image
      reader.onloadend = await function () {
        console.log('reader.result -->',reader.result);
        image = reader.result;
      }
      
      reader.onerror = (error) => {
        console.log(error);
      };
      
      console.log('out from reader');
      
      setTimeout(() => {
        console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['cheque_image'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }, 1000 * 2);
      
      reader.readAsDataURL(file);
      
    }

    async changeListener_GST($event): Promise<void> {
      
      console.log($event);
      var file = $event.target.files[0];
      console.log('file -->',file);
      var reader = await new FileReader();
      console.log('reader -->',reader);
      var image
      reader.onloadend = await function () {
        console.log('reader.result -->',reader.result);
        image = reader.result;
      }
      
      reader.onerror = (error) => {
        console.log(error);
      };
      
      console.log('out from reader');
      
      setTimeout(() => {
        console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['gst_image'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }, 1000 * 2);
      
      reader.readAsDataURL(file);
      
    }
    async changeListener_7($event): Promise<void> {
      
     
      console.log($event);
      var file = $event.target.files[0];
 
      var reader = await new FileReader();

      let image;
      reader.onloadend = ()=> {
        image = reader.result;
      // }
      
      // reader.onerror = (error) => {
      //   console.log(error);
      // };
      // setTimeout((image) => {
      //   console.log(image,'image<==');
        let reqData = {base64:image};
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.patchValue({ partner_front: resp['response'].fileName });
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }
      
      reader.readAsDataURL(file);
      
    }

    async changeListener_8($event): Promise<void> {
      
     
      console.log($event);
      var file = $event.target.files[0];
 
      var reader = await new FileReader();

      let image;
      reader.onloadend = ()=> {
        image = reader.result;
      
      
      // reader.onerror = (error) => {
      //   console.log(error);
      // };
      // setTimeout(() => {
      //   console.log(image,'image<==');
        let reqData = {base64:image};
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.patchValue({ partner_back: resp['response'].fileName });
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }
      reader.readAsDataURL(file);
      
    }
    changeListener_9($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = ()=> {
        image = reader.result;
      
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['partner_pan'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      
      // ,100}
    }
      reader.readAsDataURL(file);
      
    }
    changeListener_10($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = ()=> {
        image = reader.result;
      // }
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['proprietorship_doc'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      // }, 100);
    }
      reader.readAsDataURL(file);
      
    }
    changeListener_11($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend =  ()=> {
        image = reader.result;
      // }
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['partner_front_2'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      // }, 100);
      }
      reader.readAsDataURL(file);
      
    }
    changeListener_12($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = ()=> {
        image = reader.result;
      
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['partner_back_2'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      // }, 100);
      }
      reader.readAsDataURL(file);
      
    }
    changeListener_13($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = ()=> {
        image = reader.result;
      
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['partner_pan_2'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      // }, 100);
      }
      reader.readAsDataURL(file);
      
    }
    changeListener_1($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = ()=> {
        image = reader.result;
      // }
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['doc_image_1'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      // }, 100);
      }
      reader.readAsDataURL(file);
      
    }
    
    
    profileImageAdd($event): void {
      
      console.log($event);
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = ()=> {
        image = reader.result;
      // }
      // setTimeout(() => {
      //   console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.controls['profile_image'].setValue(resp['response'].fileName);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }
      
      reader.readAsDataURL(file);
      
    }
    openImage(data){}
   
    
    onSubmit(){
      console.log(this.form.value);
      if(this.form.invalid){
        this.form.markAllAsTouched();
        return
      }
      
      let reqData:any = {};
      reqData.basicDetail = this.form.value
      // reqData.basicDetail.cust_type = this.db.drType.filter(r=>r.id = reqData.basicDetail.cust_type_id)[0].name
      reqData.basicDetail.cust_type = this.db.drType.filter(r=>r.id == reqData.basicDetail.cust_type_id)[0].name
      console.log(reqData);
      reqData.status = this.form.value.status
      reqData.contactDetail = this.form.value.contactDetail;
      this.db.presentLoader();
      this.db.postReq(reqData,this.activatedroute.snapshot.params.id  ? 'dis_network/update_network' : 'dis_network/add_network').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.db.successAlert(resp['status'],resp['message']);
          this.backClicked();
          this.db.router.navigateByUrl('lead-list/'+this.form.value.cust_type_id,{replaceUrl:true})
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
      
    }
    
    backClicked() {
      this._location.back();
    }
    
    
    
    postalData:any=[];
    pincodeHandler(){
      let reqData = {
        filter:{pincode:this.form.value.pincode}
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'master/getDetailByPincode').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.postalData = resp['result'].data;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    selectPostal(data){
      this.form.controls['state_name'].setValue(data.state_name);
      this.form.controls['district_name'].setValue(data.district_name);
      this.form.controls['city'].setValue(data.city);
      this.form.controls['area'].setValue(data.area);
      this.form.controls['zone'].setValue(data.zone);
    }
    filteredResults:any={};
    districts:any=[]
    getDistricts(state){
      this.db.postReq({filter:{state_name:state}},'master/district_list').subscribe(async resp=>{
        this.districts=[]
        if(resp['result'].data.length) {
          await resp['result'].data.map(r=>{
            this.districts.push(r.district_name);
          })
          this.filteredResults.districts = this.districts
        }
      })  
    }
    city:any=[];
    getCity(state,district){
      this.db.postReq({filter:{state_name:state,district_name:district}},'master/city_list').subscribe(async resp=>{
        this.city=[]
        if(resp['result'].data.length) {
          await resp['result'].data.map(r=>{
            this.city.push(r.city);
          })
          this.filteredResults.city = this.city
        }
      })      
      
    }
    area:any=[];
    getArea(state,district,city){
      this.db.postReq({filter:{state_name:state,district_name:district,city:city}},'master/area_list').subscribe(async resp=>{
        this.area=[]
        if(resp['result'].data.length) {
          await resp['result'].data.map(r=>{
            this.area.push(r.area);
          })
          this.filteredResults.area = this.area
        }
      })      
      
    }
    zone:any=[];
    getZone(state,district,city,area){
      this.db.postReq({filter:{state_name:state,district_name:district,city:city,area:area}},'master/zone_list').subscribe(async resp=>{
        this.zone=[]
        if(resp['result'].data.length) {
          await resp['result'].data.map(r=>{
            this.zone.push(r.zone);
          })
          this.filteredResults.zone = this.zone
        }
      })      
      
    }
    
    // sectionData:any={};
    // showSection(row) {
    
    //   this.sectionData.collectionSectionShow = false;
    
    //   if (this.form.value.company_type == 'Pvt Ltd/PartnerShip') {
    //     this.sectionData.collectionSectionShow = true;
    //   }
    
    // }
    
    // addContactDetails(){
    //   console.log(this.form.value,this.form.value.cp_name ,this.form.value.cp_designation ,this.form.value.cp_email ,this.form.value.cp_mobile)
    //   if(!this.form.value.cp_name || !this.form.value.cp_designation || !this.form.value.cp_email || !this.form.value.cp_mobile){
    //     this.db.presentAlert('Error','Please fill all the required details to continue!');
    //     return;
    //   }
    //   var emailpattern = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    //   console.log(emailpattern.test(this.form.value.cp_email));
    
    //   if(!emailpattern.test(this.form.value.cp_email)){
    //     this.db.presentAlert('Error','Please enter a valid email!');
    //     return;
    //   }
    //   var mobilepattern = /^((\\+91-?)|0)?[0-9]{10}$/
    //   if(!mobilepattern.test(this.form.value.cp_mobile)){
    //     this.db.presentAlert('Error','Please enter a valid mobile!');
    //     return;
    //   }
    //   this.form.controls['contactDetail'].setValue([...this.form.value.contactDetail,{name:this.form.value.cp_name ,email:this.form.value.cp_email,designation:this.form.value.cp_designation,mobile:this.form.value.cp_mobile}])
    //   this.form.controls['cp_name'].setValue(null);
    //   this.form.controls['cp_designation'].setValue(null);
    //   this.form.controls['cp_mobile'].setValue(null);
    //   this.form.controls['cp_email'].setValue(null);
    // }
    // deleteContact(index){
    //   swal({
    //     title: "Are you sure?",
    //     text: "You want to delete this!",
    //     icon: "warning",
    //     buttons: ["Cancel", "Confirm"],
    //     dangerMode: true,
    //   })
    //   .then((willDelete) => {        
    //     if (willDelete) {  
    //       this.form.controls['contactDetail'].setValue(this.form.value.contactDetail.filter((r,i)=> i != index ))
    //     } else {
    //       swal("Your data is safe!");
    //     }
    //   });
    
    // }
  }