import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
// import { ProductUnitDetailModelComponent } from '../product-unit-detail-model/product-unit-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material';
// import { ProductUnitDetailModelComponent } from '../product-unit-detail-model/product-unit-detail-model.component';



interface FileNode {
  name: string;
  children?: FileNode[];
}
// const TREE_DATA: FileNode[] = [
//   {
//     name: 'Folder 1',
//     children: [
//       { name: 'File 1.1' },
//       { name: 'File 1.2' },
//       { name: 'File 1.3' },
//     ]
//   },
//   {
//     name: 'Folder 2',
//     children: [
//       { name: 'File 2.1' },
//       { name: 'File 2.2' },
//     ]
//   },
//   {
//     name: 'Folder 3',
//     children: [
//       { name: 'File 3.1' },
//       { name: 'File 3.2' },
//       { name: 'File 3.3' },
//       { name: 'File 3.4' },
//     ]
//   }
// ];
@Component({
  selector: 'app-sales-heirarchy',
  templateUrl: './sales-heirarchy.component.html',
})
export class SalesHeirarchyComponent implements OnInit {
  
  treeControl = new FlatTreeControl<FileNode>(
    node => node.children ? node.children.length : 0,
    node =>  !!node.children && node.children.length > 0
    );
    
    // treeFlattener = new MatTreeFlattener(
    //   (node: FileNode, level: number) => ({
    //     expandable: !!node.children && node.children.length > 0,
    //     name: node.name,
    //     level: level
    //   }),
    //   node => node.level,
    //   node => node.expandable,
    //   node => node.children
    //   );
      
    //   // dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
      
      data: FileNode[]
      dataNotFound=false
      reqSent=false
      autoFocus?: boolean = true;
      
      constructor(
        private _location: Location,
        public dialog: MatDialog,
        public db:DatabaseService
        ) {
          
        }
        hasChild = (_: number, node: FileNode) => !!node.children && node.children.length > 0;
        
        dataSource:any;
        ngOnInit() {
          
          this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
          this.getData();
        }
        todayDate :any
        pageNo:any=1;
        tempPageNo =1;;
        totalPages:any
        start:any=0;
        limit:any=50;
        totalLength:any;
        filter:any={};
        getData(){
          this.dataNotFound = false;
          this.reqSent = false;
          this.filter.for_sales_user =1
          let reqData = {
            limit : this.limit,
            start : this.start,
            filter : this.filter
          }
          this.db.presentLoader();
          this.db.postReq(reqData,'master/reporting_structure').subscribe(resp=>{
            this.reqSent = true;
            if(resp['status'] == 'success'){
              this.data = resp['result'].data;
              this.dataSource.data = this.data
              // if(!this.data.length) this.dataNotFound = true;
              // this.totalLength = resp['result'].totalCount;
              // this.totalPages = Math.ceil(this.totalLength / this.limit);
              // console.log(this.data);
              
              this.dataSource.data  = [
                
                {
                  
                  "name": "MAHABIR AGARWAL",
                  "children": [
                    {
                      "name": "RAVI B. MALAGIKAR"
                    },
                    {
                      "name": "RAVI B. MALAGIKAR"
                    },
                    {
                      "name": "RAVI B. MALAGIKAR"
                    }
                  ]
                },
                {
                  
                  "name": "MAHABIR AGARWAL",
                  "children": [
                    {
                      "name": "RAVI B. MALAGIKAR2",
                      "children": [
                        {
                          "name": "RAVI B. MALAGIKAR3",
                          "children": [
                            {
                              "name": "RAVI B. MALAGIKAR4",
                              "children": [
                                {
                                  "name": "RAVI B. MALAGIKAR5",
                                  "children": [
                                    {
                                      "name": "RAVI B. MALAGIKAR6",
                                      "children": [
                                        {
                                          "name": "RAVI B. MALAGIKAR7",
                                          "children": [
                                            {
                                              "name": "RAVI B. MALAGIKAR8"
                                            },
                                            {
                                              "name": "RAVI B. MALAGIKAR"
                                            },
                                            {
                                              "name": "RAVI B. MALAGIKAR"
                                            }
                                          ]
                                        },
                                        {
                                          "name": "RAVI B. MALAGIKAR"
                                        },
                                        {
                                          "name": "RAVI B. MALAGIKAR"
                                        }
                                      ]
                                    },
                                    {
                                      "name": "RAVI B. MALAGIKAR"
                                    },
                                    {
                                      "name": "RAVI B. MALAGIKAR"
                                    }
                                  ]
                                },
                                {
                                  "name": "RAVI B. MALAGIKAR"
                                },
                                {
                                  "name": "RAVI B. MALAGIKAR"
                                }
                              ]
                            },
                            {
                              "name": "RAVI B. MALAGIKAR"
                            },
                            {
                              "name": "RAVI B. MALAGIKAR"
                            }
                          ]
                        },
                        {
                          "name": "RAVI B. MALAGIKAR"
                        },
                        {
                          "name": "RAVI B. MALAGIKAR"
                        }
                      ]
                    },
                    {
                      "name": "RAVI B. MALAGIKAR"
                    },
                    {
                      "name": "RAVI B. MALAGIKAR"
                    }
                  ]
                }
                
                
              ]
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          },err=>{
            this.db.errHandler(err);
            
          })
        }
        
        delete(data){
          swal({
            title: "Are you sure?",
            text: data.status == 1  ? "You want to make this Inactive!" : "You want to make this Active!",
            icon: "warning",
            buttons: ["Cancel", "Confirm"],
            dangerMode: true,
          })
          .then((willDelete) => {        
            if (willDelete) {
              this.db.presentLoader();
              data.status = data.status == 0 ? 1 : 0;
              data.content_id = data.encrypt_id;
              this.db.postReq(data,'content/update').subscribe(resp=>{
                if(resp['status'] == 'success'){
                  swal(data.status == 0 ? "Inactive!" : "Active", {
                    icon: "success",
                  });
                }else{
                  if(resp['message']=='Invalid token'){
                    this.db.sessionExpire();
                    return;
                  }
                  this.db.presentAlert(resp['status'],resp['message'])
                }
              })
              
            } else {
              swal("Your data is safe!");
            }
          });
        }
        pagination(action){
          
          if(action == 'pageNo'){
            if(this.pageNo > 0 && this.pageNo <=this.totalPages){
              this.start = this.limit*(this.pageNo-1)
            }else{
              this.pageNo = Math.ceil(this.totalLength / this.data.length);
            }
          }
          else if(action=='next'){
            
            if(this.totalLength == (this.start+this.data.length)) return;
            
            this.start = this.start+this.limit
            this.pageNo ++ ;
          }else{
            if(this.pageNo == 1) return
            this.start = this.start-this.limit
            this.pageNo -- ;
          }
          this.getData();
          this.tempPageNo = this.pageNo
        }
        refresh(){
          this.start = 0;
          this.limit = 50;
          this.filter = {};
          this.getData();
        }
        
        backClicked() {
          this._location.back();
        }
        
        
      }
      