import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-announcement-detail',
  templateUrl: './announcement-detail.component.html'
})
export class AnnouncementDetailComponent implements OnInit {

  data:any=[];
  read:any[];
  unread:any=[];
  status:boolean = false
  tabActiveType:any={};

  constructor(private _location: Location) 
  {
    this.tabActive('tab1');
  }
  
  ngOnInit() {
    this.data = [
      {
       
        "type":"Technician",
        "name":"Gaurav Tyagi",
        "mobile":"9095855720",
        "address": "Haryana - Faridabad"
      },
      {
        "type":"Dealer",
        "name":"Indeerjeet Maurya",
        "mobile":"9878123412",
        "address": "Himachal Pradesh - Kangra"
      },
      {
        
        "type":"Dealer",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "Gujarat - Jamnagar"
      },
      {
        "type":"Dealer",
        "name":"Ajay Sharma ",
        "mobile":"9989451234",
        "address": "Haryana - Mahendragarh"
       
      },
      {
       
        "type":"Technician",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "West Bengal - Kolkata"
        
      },
   
      {
        "type":"Dealer",
        "name":"Sanjay Singh",
        "mobile":"9687701232",
        "address": "Rajasthan - Bhilwara"
       
      },

      {
        "type":"Technician",
        "name":"Pardeep Gupta",
        "mobile":"9878123490",
        "address": "West Bengal - Kolkata"
      },
      {
        "type":"Dealer",
        "name":"Pardeep Gupta",
        "mobile":"9878123490",
        "address": "Haryana - Panchkula"
      },

      {
        "type":"Technician",
        "name":"Sanjay Gupta",
        "mobile":"9907613411",
        "address": "Maharashtra - Pune"
      },

      {
        "type":"Technician",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "Punjab - Amritsar"
      },
   
      {
        
        "type":"Distributor",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "Gujarat - Jamnagar"
      },
      {
        "type":"Dealer",
        "name":"Ajay Sharma ",
        "mobile":"9989451234",
        "address": "Haryana - Mahendragarh"
       
      },
      {
       
        "type":"Distributor",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "West Bengal - Kolkata"
        
      },
   
      {
        "type":"Dealer",
        "name":"Sanjay Singh",
        "mobile":"9687701232",
        "address": "Rajasthan - Bhilwara"
       
      },

      {
        "type":"Technician",
        "name":"Pardeep Gupta",
        "mobile":"9878123490",
        "address": "West Bengal - Kolkata"
      },
    ];
    this.read = [
      {
        "type":"Dealer",
        "name":"Sanjay Singh",
        "mobile":"9687701232",
        "address": "Rajasthan - Bhilwara"
       
      },

      {
        "type":"Technician",
        "name":"Pardeep Gupta",
        "mobile":"9878123490",
        "address": "West Bengal - Kolkata"
      },
      {
        "type":"Dealer",
        "name":"Pardeep Gupta",
        "mobile":"9878123490",
        "address": "Haryana - Panchkula"
      },

      {
        "type":"Technician",
        "name":"Sanjay Gupta",
        "mobile":"9907613411",
        "address": "Maharashtra - Pune"
      },

      {
        "type":"Technician",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "Punjab - Amritsar"
      },
      {
       
        "type":"Technician",
        "name":"Gaurav Tyagi",
        "mobile":"9095855720",
        "address": "Haryana - Faridabad"
      },
      {
        "type":"Dealer",
        "name":"Indeerjeet Maurya",
        "mobile":"9878123412",
        "address": "Himachal Pradesh - Kangra"
      },
      {
        
        "type":"Dealer",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "Gujarat - Jamnagar"
      },
      {
        "type":"Dealer",
        "name":"Ajay Sharma ",
        "mobile":"9989451234",
        "address": "Haryana - Mahendragarh"
       
      },
      {
       
        "type":"Technician",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "West Bengal - Kolkata"
        
      },
   
  
    ];
    this.unread = [
      {
        
        "type":"Distributor",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "Gujarat - Jamnagar"
      },
      {
        "type":"Dealer",
        "name":"Ajay Sharma ",
        "mobile":"9989451234",
        "address": "Haryana - Mahendragarh"
       
      },
      {
       
        "type":"Distributor",
        "name":"Anoop Kumar",
        "mobile":"9987901234",
        "address": "West Bengal - Kolkata"
        
      },
   
      {
        "type":"Dealer",
        "name":"Sanjay Singh",
        "mobile":"9687701232",
        "address": "Rajasthan - Bhilwara"
       
      },

      {
        "type":"Technician",
        "name":"Pardeep Gupta",
        "mobile":"9878123490",
        "address": "West Bengal - Kolkata"
      },
    ];
  }
  
  backClicked() {
    this._location.back();
  }
  tabActive(tab:any)
  {
    this.tabActiveType = {};
    this.tabActiveType[tab] = true; 
  }
}
