import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
// import { ExecutiveModelComponent } from '../../../../executive-model/executive-model.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit {
  status:boolean = false
  
  constructor(public dialog : MatDialog, public activatedroute:ActivatedRoute , private render: Renderer2,private _location: Location,public db:DatabaseService) { }

  backClicked() {
    this._location.back();
    setTimeout(() => {
      if(this.activatedroute.snapshot.params.id)this.getDetail(this.activatedroute.snapshot.params.id);
    }, 1000);

    // location.reload();
    this.status = !this.status;
    if(this.status) {
        //this.render.removeClass(document.body, 'toggle-active-tlp');
    }
    else {
        //this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }
 
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  ngOnChanges(){
    console.log('ngOnChanges');
    
  }
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'user_id':id},'master/sales_user/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data'];   
        console.log(this.data);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
 

  delete(){
    swal({
      title: "Are you sure?",
      text: "You want to delete this",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
        // data.status = data.status == 0 ? 1 : 0;
       
        this.db.postReq({'user_id':this.data.detail.id,status:0},'master/system_user/delete').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal("Deleted", {
              icon: "success",
            });
            this.backClicked();
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
  }
}
