import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-dirtribution-info-edit-model',
  templateUrl: './dirtribution-info-edit-model.component.html'
})
export class DirtributionInfoEditModelComponent implements OnInit {
  
  constructor(public matdialog:MatDialog, @Inject(MAT_DIALOG_DATA) public data :any ,public db :DatabaseService) { }
  
  ngOnInit() {
    console.log(this.data);
    this.formsInit();
  }
  form: FormGroup;
  
  formsInit() {
    this.form = this.db.formBuilder.group({
      email: [ this.data.email ? this.data.email : '' , [Validators.required,Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]],
      mobile: [ this.data.mobile ? this.data.mobile : '' ,[Validators.required,Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/)]],
      company_name: [ this.data.company_name ? this.data.company_name : '' ,[Validators.required]],
      cust_code: [ this.data.cust_code ? this.data.cust_code : '' , [Validators.required]],
      // username: [this.data.username ? this.data.username : null, [Validators.required]],
      // password: [this.data.password ? this.data.password : null, [Validators.required]],
      // credit_day: [this.data.credit_day ? this.data.credit_day : null, [Validators.required]],
      state_name: [this.data.state_name ? this.data.state_name : null, [Validators.required]],
      city: [this.data.city ? this.data.city : null, [Validators.required]],
      area: [this.data.area ? this.data.area : null, []],
      zone: [this.data.zone ? this.data.zone : null, []],
      district_name: [this.data.district_name ? this.data.district_name : null, [Validators.required]],
      street: [this.data.street ? this.data.street : null, [Validators.required]],
      pincode: [this.data.pincode ? this.data.pincode : null, []],
      landline_no: [this.data.landline_no ? this.data.landline_no : null, [Validators.required]],
      gst_no: [this.data.gst_no ? this.data.gst_no : null, [Validators.required,Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/),Validators.minLength(15)]],
      status: [this.data.status ? this.data.status.toString() : null, [Validators.required]],
      customer_id: [this.data.id ? this.data.id : null],
      cust_type: [this.data.cust_type ? this.data.cust_type : null],
      cust_type_id: [this.data.cust_type_id ? this.data.cust_type_id : null],
    })
    console.log(this.form.value)

    this.db.getStates()
    this.db.getDistricts(this.form.value.state_name)
    this.db.getCity(this.form.value.state_name,this.form.value.district_name)
    this.db.getArea(this.form.value.state_name,this.form.value.district_name,this.form.value.city)
    this.db.getZone(this.form.value.state_name,this.form.value.district_name,this.form.value.city,this.form.value.area)
    this.db.getPincode(this.form.value.state_name,this.form.value.district_name,this.form.value.city,this.form.value.area,this.form.value.zone)
    this.pincodeHandler();
  }
  onSubmit(){
    console.log(this.form);
    
    if(this.form.invalid){
      this.form.controls['email'].markAsTouched();
      this.form.controls['mobile'].markAsTouched();
      this.form.controls['company_name'].markAsTouched();
      this.form.controls['cust_code'].markAsTouched();
      this.form.controls['username'].markAsTouched();
      this.form.controls['password'].markAsTouched();
      this.form.controls['state_name'].markAsTouched();
      this.form.controls['city'].markAsTouched();
      this.form.controls['area'].markAsTouched();
      this.form.controls['zone'].markAsTouched();
      this.form.controls['district_name'].markAsTouched();
      this.form.controls['street'].markAsTouched();
      this.form.controls['pincode'].markAsTouched();
      this.form.controls['lanline_no'].markAsTouched();
      return
    }
    console.log(this.form.value);
    let reqData = {
      customer_id:this.data.id,
      // cust_type:this.form.value
      basicDetail: this.form.value
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'dis_network/update_basic_detail_network').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.matdialog.closeAll()        
      }else{
if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  postalData:any=[];
  pincodeHandler(){
    let reqData = {
      filter:{pincode:this.form.value.pincode}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/getDetailByPincode').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.postalData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  selectPostal(data){
    this.form.controls['state_name'].setValue(data.state_name);
    this.form.controls['district_name'].setValue(data.district_name);
    this.form.controls['city'].setValue(data.city);
    this.form.controls['area'].setValue(data.area);
    this.form.controls['zone'].setValue(data.zone);
  }

}
