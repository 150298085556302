import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-us-primary-order-list',
  templateUrl: './us-primary-order-list.component.html'
})
export class UsPrimaryOrderListComponent implements OnInit {
 
  data:any=[];
  dataNotFound=false
  reqSent=false
  statusData:any=[];
  tabActiveType:any={};
  
  constructor(public activatedRouted:ActivatedRoute,public event:CustomEventsService,private _location: Location, public dialog: MatDialog,public db:DatabaseService) 
  {
    // this.getOrderStatus()
    this.filter.cust_type='';
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    this.getData();
   
  }
  
  ngOnInit() {
    
    
  }
  
  backClicked() {
    this._location.back();
  }
  
  
  
  todayDate :any
  pageNo:any=1;
  tempPageNo =1;
  totalPages:any
  start:any=0;
  limit:any=50;
  totalLength:any;
  filter:any={};
  getData(){
    this.dataNotFound = false;
    this.reqSent = false;
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter,
      type:'primary'
    }
    reqData.filter.encrypt_user_id  = this.activatedRouted.snapshot.params.id

    this.db.presentLoader();
    this.db.postReq(reqData,'order/list').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.data = resp['result'].data;
        if(!this.data.length) this.dataNotFound = true;
        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  delete(data){}
  pagination(action){
    
    if(action == 'pageNo'){
      if(this.pageNo > 0 && this.pageNo <=this.totalPages){
        this.start = this.limit*(this.pageNo-1)
      }else{
        this.pageNo = Math.ceil(this.totalLength / this.data.length);
      }
    }
    else if(action=='next'){
      
      if(this.totalLength == (this.start+this.data.length)) return;
      
      this.start = this.start+this.limit
      this.pageNo ++ ;
    }else{
      if(this.pageNo == 1) return
      this.start = this.start+this.limit
      this.pageNo -- ;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }
  refresh(){
    this.start = 0;
    this.limit = 50;
    let order_status = this.filter.order_status; 
    this.filter={};
    this.filter.order_status = order_status;    
    this.getData();
  }
}
