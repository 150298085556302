import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
})
export class AddComponent implements OnInit {

  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    this.getTypes();
    this.db.getStates()
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }
  types:any=[];
  getTypes(){
    this.db.presentLoader();
    
    this.db.postReq({},'content/type_list').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
       this.types = resp['result'].data
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }

  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'content_id':id},'content/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      file_path: [data.file_path ? data.file_path : null],
      file: [data.file ? data.file : null , []],
      file_path_loading:[false],
      title: [data.title ? data.title : null ,[Validators.required]],
      description: [data.description ? data.description : null ,[Validators.required]],
      youtube_url: [data.youtube_url ? data.youtube_url : null ,[]],
      youtube_id: [data.youtube_id ? data.youtube_id : null ,[]],
      ordering: [data.ordering ? data.ordering : null ,[Validators.required]],
      status: [data.status ? data.status.toString() :"1" , [Validators.required]],
      type: [data.type ? data.type :"1" , [Validators.required]],
      content_id: [data.encrypt_id ? data.encrypt_id : null],
    })
  }
  changeListener($event): void {
    console.log($event);
    this.form.controls['file_path_loading'].setValue(true);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var file
    reader.onloadend = function () {
      file = reader.result;
    }
    setTimeout(() => {
      console.log(file);
      let reqData = {base64:file}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
         this.form.controls['file_path'].setValue(resp['response'].fileName);
         this.form.controls['file_path_loading'].setValue(false);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  open(src){
    window.open(src,'_blank')
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    console.log(this.form.value);
    let reqData= this.form.value
    // reqData.type='faq';
    this.db.presentLoader();
    this.db.postReq(reqData,this.actRoute.snapshot.params.id ? 'content/update' : 'content/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }

}
