import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CustomEventsService } from '../../_services/custom-events.service';


@Component({
  selector: 'app-us-distributors-lis',
  templateUrl: './us-distributors-lis.component.html'
})
export class UsDistributorsLisComponent implements OnInit {
   

  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    public activatedRouted:ActivatedRoute,
    public event:CustomEventsService
    ) { }
    ngOnInit() {
      // this.getDetail(this.activatedRouted.snapshot.params.id);
      if(this.db.router.url.includes('us-dealer-list')) {
        console.log('hererere   ***,us-dealer-list');
        this.filter.cust_type_id = 2;  
      }
      if(this.db.router.url.includes('us-architect-list')) {
        console.log('hererere   ***,us-architect-list');
        this.filter.cust_type_id = 4;  
      }
      if(this.db.router.url.includes('us-carpenter-list')) {
        console.log('hererere   ***,us-carpenter-list');
        this.filter.cust_type_id = 3;  
      }
      this.getData();
      this.filter.status = ''
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')

      
    };

    // stateName:any = '';
    // getDetail(id) {
    //   this.db.presentLoader();
    //   this.db.postReq({'user_id':id},'master/sales_user/detail').subscribe(resp=>{
    //     this.db.dismissLoader();
    //     if(resp['status'] == 'success'){
    //       this.stateName = resp['data'].assignedState['0'].state_name;
    //       // console.log("resp['data']",resp['data'].assignedState['0'].state_name);
    //     }else{
    //       if(resp['message']=='Invalid token'){
    //         this.db.sessionExpire();
    //         return;
    //       }
    //       this.db.presentAlert(resp['status'],resp['message'])
    //     }
    //   },err=>{
    //     this.db.errHandler(err);
    //   })
    // }

    type:any
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;;
    totalPages:any
    start:any=0;
    limit:any=50;
    // working_state:any;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      this.filter.is_lead = 0;
      // this.filter.state_name = this.stateName;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
      };

      console.log('this.activatedRouted.snapshot.params',this.activatedRouted.snapshot.params);      
      reqData.filter.encrypt_user_id  = this.activatedRouted.snapshot.params.id
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    }  
   
    
    downloadExcel(){
      let reqData = {
        filter : this.filter
      }
      reqData.filter.encrypt_user_id  = this.activatedRouted.snapshot.params.id
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
  }
  