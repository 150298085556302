import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material'
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guard/authGuard';
import { AuthGuardLog } from './_guard/authGuardLog';
import { AuthService } from './_services/auth.service';
import { DatabaseService } from './_services/database.service';
import { DialogService } from './_services/dialog.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeftnavigationComponent } from './leftnavigation/leftnavigation.component';
import { HeaderComponent } from './header/header.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ProductAddComponent } from './product/product-add/product-add.component';
// import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { ProductUnitAddComponent } from './product-unit/product-unit-add/product-unit-add.component';
// import { ProductUnitDetailComponent } from './product-unit/product-unit-detail/product-unit-detail.component';
import { ProductUnitListComponent } from './product-unit/product-unit-list/product-unit-list.component';
import { ProductUnitDetailModelComponent } from './product-unit/product-unit-detail-model/product-unit-detail-model.component';
import { ProductDetailModelComponent } from './product/product-detail-model/product-detail-model.component';
import { BrandAddComponent } from './brand/brand-add/brand-add.component';
import { BrandDetailModelComponent } from './brand/brand-detail-model/brand-detail-model.component';
import { BrandListComponent } from './brand/brand-list/brand-list.component';
import { ProductCategoryAddComponent } from './product-category/product-category-add/product-category-add.component';
import { ProductCategoryDetailModelComponent } from './product-category/product-category-detail-model/product-category-detail-model.component';
import { ProductCategoryListComponent } from './product-category/product-category-list/product-category-list.component';
import { HsnCodeAddComponent } from './hsn-code/hsn-code-add/hsn-code-add.component';
import { HsnCodeDetailModelComponent } from './hsn-code/hsn-code-detail-model/hsn-code-detail-model.component';
import { HsnCodeListComponent } from './hsn-code/hsn-code-list/hsn-code-list.component';
import { PostalMasterListComponent } from './postal-master/postal-master-list/postal-master-list.component';
import { DrListComponent } from './dr-network/dr-list/dr-list.component';
import { DrAddComponent } from './dr-network/dr-add/dr-add.component';
import { DrDetailComponent } from './dr-network/dr-detail/dr-detail.component';
import { PageMenuComponent } from './dr-network/page-menu/page-menu.component';
import { DirtributionInfoEditModelComponent } from './dr-network/dirtribution-info-edit-model/dirtribution-info-edit-model.component';
import { ContactEditModelComponent } from './dr-network/contact-edit-model/contact-edit-model.component';
import { InvoiceAddComponent } from './invoice/invoice-add/invoice-add.component';
import { InvoiceListComponent } from './invoice/invoice-list/invoice-list.component';
import { InvoiceModelComponent } from './invoice/invoice-model/invoice-model.component';
import { OrderAddComponent } from './order/order-add/order-add.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { VendorContactEditModelComponent } from './vendor/vendor-contact-edit-model/vendor-contact-edit-model.component';
import { VendorListComponent } from './vendor/vendor-list/vendor-list.component';
import { VendorPageMenuComponent } from './vendor/vendor-page-menu/vendor-page-menu.component';
import { VendorAddComponent } from './vendor/vendor-add/vendor-add.component';
import { VendorDetailComponent } from './vendor/vendor-detail/vendor-detail.component';
import { VendorInfoEditModelComponent } from './vendor/vendor-info-edit-model/vendor-info-edit-model.component';
import { RoleAddComponent } from './user-role-master/role-add/role-add.component';
import { RoleDetailModelComponent } from './user-role-master/role-detail/role-detail-model.component';
import { RoleListComponent } from './user-role-master/role-list/role-list.component';
import { PostalAddComponent } from './postal-master/postal-add/postal-add.component';
import { BrandAssignedListComponent } from './dr-network/brands-assigned/brand-assigned-list.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UsersAddComponent } from './users/users-add/users-add.component';
import { MatAutocompleteModule } from '@angular/material';
import { PurchaseOrderAddComponent } from './purchase-order/purchase-order-add/purchase-order-add.component';
import { PurchaseOrderDetailComponent } from './purchase-order/purchase-order-detail/purchase-order-detail.component';
import { PurchaseOrderListComponent } from './purchase-order/purchase-order-list/purchase-order-list.component';
import { StockReportComponent } from './reports/stock-report/stock-report.component';
import { SalesReportComponent } from './reports/sales-report/sales-report.component';
import { ProductGroupStockReportComponent } from './reports/product-group-report/product-group-report.component';
import { DrOrdersComponent } from './dr-network/dr-orders/dr-orders.component';
import { DrInvoicesComponent } from './dr-network/dr-invoices/dr-invoices.component';
import { PoComponent } from './vendor/po/po.component';
import { DrProductDiscountsComponent } from './dr-network/dr-product-discounts/dr-product-discounts.component';
import { DrDetailHeaderComponent } from './dr-network/dr-detail-header/dr-detail-header.component';
import { VendorDetailHeaderMenuComponent } from './vendor/vendor-detail-header/vendor-detail-header.component';
import { ProductDiscountModelComponent } from './dr-network/product-discount/product-discount.component';
import { NumberDirective } from './_directives/numbers-only.directive';
import { InvoiceDetailComponent } from './invoice/invoice-detail/invoice-detail.component';
import { OrderDetailStatusComponent } from './order/order-detail-status/order-detail-status.component';
import { SkeletonListComponent } from './skeleton/skeleton-list/skeleton-list.component';
import { SkeletonDetailComponent } from './skeleton/skeleton-detail/skeleton-detail.component';
import { SkeletonAddComponent } from './skeleton/skeleton-add/skeleton-add.component';
import { ProductDiscountComponent } from './product-discount/product-discount.component';
import { ProductGroupStockReportDetailComponent } from './reports/product-group-report detail/product-group-report-detail.component';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { PaymentComponent } from './payment/payment/payment.component';
import { PaymentAddModelComponent } from './payment/payment-add-model/payment-add-model.component';
import { AttandanceListComponent } from './attandance-list/attandance-list.component';
import { CheckInListComponent } from './check-in-list/check-in-list.component';
import { LeaveListComponent } from './leave-list/leave-list.component';
import { RemarkModelComponent } from './remark-model/remark-model.component';
import { PaymentStatusModelComponent } from './payment/payment-status-model/payment-status-model.component';
import { TravelListComponent } from './travel/travel-list/travel-list.component';
import { TravelAddComponent } from './travel/travel-add/travel-add.component';
import { ExpenseListComponent } from './expense/expense-list/expense-list.component';
import { AnnouncementListComponent } from './announcement/announcement-list/announcement-list.component';
import { ExpenseAddComponent } from './expense/expense-add/expense-add.component';
import { AnnouncementAddComponent } from './announcement/announcement-add/announcement-add.component';
import { SiteListComponent } from './site/site-list/list.component';
import { FeedBackListComponent } from './feedback/list/list.component';
import { GiftListComponent } from './gift-gallery/list/list.component';
import { OfferListComponent } from './offer/list/list.component';
import { OfferAddComponent } from './offer/add/add.component';
import { ChatDetailModelComponent } from './feedback/detail/detail-model.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { VideoListComponent } from './videos/video-list/list.component';
import { VideoAddModelComponent } from './videos/video-add-model/video-add-model.component';
import { GiftDetailComponent } from './gift-gallery/gift-detail/gift-detail.component';
import { siteDetailComponent } from './site/detail/detail.component';
import { OfferDetailComponent } from './offer/detail/detail.component';
import { FaqAddComponent } from './faq/add/add.component';
import { FaqListComponent } from './faq/list/list.component';
import { ReferralListComponent } from './referel/referral-list/referral-list.component';
import { ReferralEditModelComponent } from './referel/referral-edit-model/referral-edit-model.component';
import { LeadListComponent } from './leads/lead-list/list.component';

import { StatusModelComponent } from './leads/status-model/status-model.component';
import { DealerBasicComponent } from './leads/detail/dealer-basic/dealer-basic.component';
import { DealerCouponListComponent } from './leads/detail/dealer-coupon-list/dealer-coupon-list.component';
import { DealerPointsListComponent } from './leads/detail/dealer-points-list/dealer-points-list.component';
import { DealerRedeemListComponent } from './leads/detail/dealer-redeem-list/dealer-redeem-list.component';
// import { HighchartsChartModule } from 'highcharts-angular';
import { DealerTopHeaderComponent } from './leads/detail/dealer-top-header/dealer-top-header.component';
import { DealerTabComponent } from './leads/detail/dealer-tab/dealer-tab.component';
import { GiftGalleryAddComponent } from './gift-gallery/gift-gallery-add/gift-gallery-add.component';
import { RedeemDetailComponent } from './redeem-req/redeem-detail/redeem-detail.component';
import { RedeemReqListComponent } from './redeem-req/redeem-req-list/redeem-req-list.component';
import { CuponCodeListComponent } from './coupon/cupon-code-list/cupon-code-list.component';
import { RedeemRequestStatusModelComponent } from './redeem-req/redeem-status-model/status-model.component';
import { GiftStatusModelComponent } from './redeem-req/gift-status-model/status-model.component';
import { CouponAddComponent } from './coupon/coupon-add/add.component';
import { BannerAddComponent } from './banner/add/add.component';
import { BannerListComponent } from './banner/list/list.component';
import { AddBonusModelComponent } from './leads/add-bonus-points/add-bonus.component';
import { NewsAddComponent } from './news/add/add.component';
import { NewsListComponent } from './news/list/list.component';
import { NotificationsListComponent } from './notifications/list/list.component';
import { NotificationsAddComponent } from './notifications/add/add.component';
import { LeadAddComponent } from './leads/lead-add/lead-add.component';
import { CouponDetailModelComponent } from './coupon/coupon-detail/coupon-detail-model.component';
import { TempUsersListComponent } from './temp-users/temp-users.component';
import { NotificationDetailModelComponent } from './notifications/notification-detail-modal/notification-detail-model.component';
import { LeaveStatusModelComponent } from './leave-status/status-model.component';
import { UsLeaveListComponent } from './sales-user/us-leave-list/us-leave-list.component';
import { UsCheckinsListComponent } from './sales-user/us-checkins-list/us-checkins-list.component';
import { UsDistributorsLisComponent } from './sales-user/us-distributors-lis/us-distributors-lis.component';
import { UsExpenseListComponent } from './sales-user/us-expense-list/us-expense-list.component';
import { UsFollowupListComponent } from './sales-user/us-followup-list/us-followup-list.component';
import { UsLeadListComponent } from './sales-user/us-lead-list/us-lead-list.component';
import { UsPaymentListComponent } from './sales-user/us-payment-list/us-payment-list.component';
import { UsPrimaryOrderListComponent } from './sales-user/us-primary-order-list/us-primary-order-list.component';
import { UsSecondaryOrderListComponent } from './sales-user/us-secondary-order-list/us-secondary-order-list.component';
import { UsTargetListComponent } from './sales-user/us-target-list/us-target-list.component';
import { UsTravelListComponent } from './sales-user/us-travel-list/us-travel-list.component';
import { UserAddComponent } from './sales-user/user-add/user-add.component';
import { UserDetailComponent } from './sales-user/user-detail/user-detail.component';
import { UserListComponent } from './sales-user/user-list/user-list.component';
import { SalesUserTabComponent } from './sales-user/sales-user-tab/sales-user-tab.component';
import { FollowupListComponent } from './followup-list/followup-list.component';
import { FollowupModelComponent } from './followup-model/followup-model.component';
import { ExpenseStatusModelComponent } from './expense/status-modal/status-model.component';
import { ExpenseDetailComponent } from './expense/expense-detail/expense-detail.component';
import { TravelDetailComponent } from './travel/travel-detail/travel-detail.component';
import { TravelStatusModelComponent } from './travel/status-modal/status-model.component';
import { PopListComponent } from './pop/list/list.component';
import { EnquiryListComponent } from './enquiry/list/list.component';
import { PopAddComponent } from './pop/add/add.component';
import { EventStatusModelComponent } from './event-plan/status-modal/status-model.component';
import { EventListComponent } from './event-plan/event-list/event-list.component';
import { DealerFollowupListComponent } from './leads/detail/dealer-followup-list/dealer-followup-list.component';
import { DealerCheckinListComponent } from './leads/detail/dealer-checkin-list/dealer-checkin-list.component';
import { LeadAddRemarkModelComponent } from './leads/detail/add-remark/add-remark.component';
import { PopDetailComponent } from './pop/detail/detail.component';
import { AssignItemModelComponent } from './pop/assign-item/assign-item.component';
import { AddStockModelComponent } from './pop/add-stock/add-stock.component';
import { EventDetailComponent } from './event-plan/detail/detail.component';
import { LeadStatusModelComponent } from './leads/detail/lead-status-model/lead-status-model.component';
import { HolidayListComponent } from './holiday/list/list.component';
import { HolidayAddComponent } from './holiday/add/add.component';
import { NotificationDetailComponent } from './notifications/detail/detail.component';
import { AllowanceListComponent } from './allowance-list/allowance-list.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AttendanceDetailComponent } from './attandance-list/attendance-detail/attendance-detail.component';
import { ViewImageModelComponent } from './view-image/view-image.component';
import { AgmCoreModule } from '@agm/core';
import { DashboardLmsComponent } from './dashboard-lms/dashboard-lms.component';
import { LeadTypeModelComponent } from './leads/detail/lead-type-change/lead-type-change.component';
import { BankAddComponent } from './bank/add/add.component';
import { BankListComponent } from './bank/list/list.component';
// import { GoogleMapsModule } from '@angular/google-maps'
import { AgmDirectionModule } from 'agm-direction';
import { BranchListComponent } from './branch/list/list.component';
import { BranchAddComponent } from './branch/add/add.component';
import { SalesHeirarchyComponent } from './sales-heirarchy/sales-heirarchy.component';
import { LiveTrackComponent } from './live-track/live-track.component';
import { LiveTrackDetailComponent } from './live-track-detail/live-track-detail.component';
import { LeaveListDetailModelComponent } from './leave-list/leave-list-detail-model/leave-list-detail-model.component';
import { AddComponent } from './content/add/add.component';
import { ListComponent } from './content/list/list.component';
import { AdvancedModeGraphModelComponent } from './advanced-mode-graph-model/advanced-mode-graph-model.component';
import { LimitToDirective } from './_directives/limit-to.directive';
import { AnnouncementDetailComponent } from './announcement/announcement-detail/announcement-detail.component';
import { ProductGroupAddComponent } from './product-group/product-group-add/product-group-add.component';
import { ProductGroupDetailModelComponent } from './product-group/product-group-detail-model/product-group-detail-model.component';
import { ProductGroupListComponent } from './product-group/product-group-list/product-group-list.component';
import { UserHeaderComponent } from './sales-user/user-header/user-header.component';

@NgModule({
  entryComponents:[
    OrderDetailComponent,
    InvoiceModelComponent,
    ProductUnitDetailModelComponent,
    DirtributionInfoEditModelComponent,
    ProductDiscountModelComponent,
    ContactEditModelComponent,
    VendorInfoEditModelComponent,
    OrderDetailStatusComponent,
    VendorContactEditModelComponent,
    ProductDetailModelComponent,
    HsnCodeDetailModelComponent,
    BrandDetailModelComponent,
    ProductCategoryDetailModelComponent,
    RoleDetailModelComponent,
    ProductGroupStockReportDetailComponent,
    PaymentAddModelComponent,
    RemarkModelComponent,
    PaymentStatusModelComponent,
    ChatDetailModelComponent,
    VideoAddModelComponent,
    ReferralEditModelComponent,
    StatusModelComponent,
    RedeemRequestStatusModelComponent,
    GiftStatusModelComponent,
    AddBonusModelComponent,
    CouponDetailModelComponent,
    NotificationDetailModelComponent,
    LeaveStatusModelComponent,
    FollowupModelComponent,
    ExpenseStatusModelComponent,
    TravelStatusModelComponent,
    EventStatusModelComponent,
    LeadAddRemarkModelComponent,
    AssignItemModelComponent,
    AddStockModelComponent,
    LeadStatusModelComponent,
    NotificationDetailComponent,
    ViewImageModelComponent,
    LeadTypeModelComponent,
    LeaveListDetailModelComponent,
    AdvancedModeGraphModelComponent,
    AnnouncementDetailComponent,
    ProductGroupAddComponent,
    ProductGroupDetailModelComponent,
    ProductGroupListComponent,
    UserHeaderComponent,
    



  ]
  ,
  declarations: [
    NumberDirective,
    AppComponent,
    LoginComponent,
    DashboardComponent,
    DashboardLmsComponent,
    LeftnavigationComponent,
    HeaderComponent,
    ProductListComponent,
    ProductAddComponent,
    ProductDetailModelComponent,
    ProductUnitListComponent,
    ProductUnitAddComponent,
    ProductUnitDetailModelComponent,
    BrandListComponent,
    BrandAddComponent,
    BrandDetailModelComponent,
    ProductCategoryListComponent,
    ProductCategoryAddComponent,
    ProductCategoryDetailModelComponent,
    HsnCodeAddComponent,
    HsnCodeDetailModelComponent,
    HsnCodeListComponent,
    PostalMasterListComponent,
    DrListComponent,
    DrAddComponent,
    DrDetailComponent,
    PageMenuComponent,
    VendorDetailHeaderMenuComponent,
    DrDetailHeaderComponent,
    DirtributionInfoEditModelComponent,
    ProductDiscountModelComponent,
    ContactEditModelComponent,
    InvoiceAddComponent,
    InvoiceListComponent,
    InvoiceModelComponent,
    OrderAddComponent,
    OrderListComponent,
    OrderDetailComponent,
    PurchaseOrderAddComponent,
    PurchaseOrderListComponent,
    PurchaseOrderDetailComponent,
    VendorListComponent,
    VendorAddComponent,
    VendorDetailComponent,
    VendorPageMenuComponent,
    VendorInfoEditModelComponent,
    VendorContactEditModelComponent,
    RoleAddComponent,
    RoleDetailModelComponent,
    ProductGroupStockReportDetailComponent,
    RoleListComponent,
    PostalAddComponent,
    BrandAssignedListComponent,
    UsersListComponent,
    UsersAddComponent,
    StockReportComponent,
    SalesReportComponent,
    ProductGroupStockReportComponent,
    DrOrdersComponent,
    DrInvoicesComponent,
    PoComponent,
    DrProductDiscountsComponent,
    OrderDetailStatusComponent ,
    InvoiceDetailComponent,
    OrderDetailStatusComponent,
    SkeletonListComponent,
    SkeletonDetailComponent,
    SkeletonAddComponent,
    ProductDiscountComponent,
    NumberToWordsPipe,
    PaymentComponent,
    PaymentAddModelComponent,
    AttandanceListComponent,
    CheckInListComponent,
    LeaveListComponent,
    RemarkModelComponent,
    PaymentStatusModelComponent,
    TravelListComponent,
    TravelAddComponent,
    ExpenseListComponent,
    ExpenseAddComponent,
    AnnouncementAddComponent,
    AnnouncementListComponent,
    SiteListComponent,
    FeedBackListComponent,
    GiftListComponent,
    OfferListComponent,
    OfferAddComponent,
    ChatDetailModelComponent,
    VideoListComponent,
    VideoAddModelComponent,
    GiftDetailComponent,
    siteDetailComponent,
    OfferDetailComponent,
    FaqAddComponent,
    FaqListComponent,
    ReferralListComponent,
    ReferralEditModelComponent,
    LeadListComponent,

    StatusModelComponent,
    DealerBasicComponent,
    DealerCouponListComponent,
    DealerPointsListComponent,
    DealerRedeemListComponent,
    DealerTabComponent,
    DealerTopHeaderComponent,
    DealerFollowupListComponent,
    DealerCheckinListComponent,
    GiftGalleryAddComponent,
    RedeemDetailComponent,
    RedeemReqListComponent,
    CuponCodeListComponent,
    RedeemRequestStatusModelComponent,
    GiftStatusModelComponent,
    CouponAddComponent,
    BannerAddComponent,
    BannerListComponent,
    AddBonusModelComponent,
    NewsAddComponent,
    NewsListComponent,
    NotificationsListComponent,
    NotificationsAddComponent,
    LeadAddComponent,
    CouponDetailModelComponent,
    TempUsersListComponent,
    NotificationDetailModelComponent,
    LeaveStatusModelComponent,
    UserAddComponent,
    UserListComponent,
    UserDetailComponent,
    UsTargetListComponent,
    UsLeadListComponent,
    UsDistributorsLisComponent,
    UsFollowupListComponent,
    UsCheckinsListComponent,
    UsPrimaryOrderListComponent,
    UsSecondaryOrderListComponent,
    UsPaymentListComponent,
    UsTravelListComponent,
    UsExpenseListComponent,
    UsLeaveListComponent,
    SalesUserTabComponent,
    FollowupListComponent,
    FollowupModelComponent,
    ExpenseStatusModelComponent,
    ExpenseDetailComponent,
    TravelDetailComponent,
    TravelStatusModelComponent,
    PopListComponent,
    EnquiryListComponent,
    PopAddComponent,
    PopDetailComponent,
    EventListComponent,
    EventStatusModelComponent,
    LeadAddRemarkModelComponent,
    StatusModelComponent,
    AssignItemModelComponent,
    AddStockModelComponent,
    EventDetailComponent,
    LeadStatusModelComponent,
    HolidayListComponent,
    HolidayAddComponent,
    NotificationDetailComponent,
    AllowanceListComponent,
    AttendanceDetailComponent,
    ViewImageModelComponent,
    LeadTypeModelComponent,
    BankAddComponent,
    BankListComponent,
    BranchAddComponent,
    BranchListComponent,
    SalesHeirarchyComponent,
    LiveTrackComponent,
    LiveTrackDetailComponent,
    LeaveListDetailModelComponent,
    AddComponent,
    ListComponent,
    AdvancedModeGraphModelComponent,
    LimitToDirective,
    AnnouncementDetailComponent,
    ProductGroupAddComponent,
    ProductGroupDetailModelComponent,
    ProductGroupListComponent,
    UserHeaderComponent

    

    
  ],
  
  
  imports: [
    IvyCarouselModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    NoopAnimationsModule,
    MatAutocompleteModule,
    RichTextEditorAllModule,
    ChartsModule,
    AgmCoreModule.forRoot({ // @agm/core
      apiKey: 'AIzaSyBNvW6MQ2pL1Zl0O7rPu65wHkX5cFr6AVo',
    }),
    AgmDirectionModule,      // agm-direction
    // GoogleMapsModule
    // HighchartsChartModule

  ],
  providers: [
    DatabaseService,
    DialogService,
    AuthGuard,
    AuthGuardLog,
    AuthService,
    ThemeService
  ], 
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
